<template>
  <div>
      <default-app-bar > 
         <template v-slot:title>
          <div class="text-h3 mr-2"> Crédito {{info.id}} </div>
         </template>
    
         
         <template v-slot:actions>
              <router-link  to="/admin/solicitudes/list-desembolsado"  class="mr-2 white-text" >
                Volver
              </router-link>
          
         
         
         </template>
       </default-app-bar>

    <v-card v-if="info" class="px-3" :key="reloadKey">
        <v-container>
        
           
           <v-row no-gutters> 
            <v-col cols="12" sm="12" class="form-col"  > 
            <!--ng-info type="info-solicitud"  header="info_solicitud" -->
            <InfoSolicitud :data="info"></InfoSolicitud>
            <!--/ng-info-->
            
            </v-col>
           </v-row>
         </v-container>
         <div class="pb-6 mt-2" v-if="info.bus_tipo_estado_solicitud_id == 4 || info.bus_tipo_estado_solicitud_id == 5">
          <v-expansion-panels accordion v-model="panel" multiple   >  
           <v-expansion-panel > 
              <v-expansion-panel-header color="primary" class="white-text">Plan de Pagos</v-expansion-panel-header> 
                 <v-expansion-panel-content> 
                 <PlanPagos :solicitud="info.id"  ></PlanPagos>
                 </v-expansion-panel-content> 
           </v-expansion-panel>
           
           <v-expansion-panel> 
              <v-expansion-panel-header color="primary" class="white-text">Cuotas</v-expansion-panel-header> 
                 <v-expansion-panel-content class="py-4"> 
                 <CuotasCredito :solicitud="info.id" ></CuotasCredito>
                 </v-expansion-panel-content> 
           </v-expansion-panel>
           
        
      </v-expansion-panels>
     </div>
  </v-card>
     
      

</div>

</template>

<script>
  import { VCard, VCardText, VCardActions, VCardTitle, VContainer, VRow, VCol, VBtn, VIcon, VDatePicker, VCombobox, VSelect, VDivider } from 'vuetify/lib'

  import InfoSolicitud from './InfoSolicitud';
  import PlanPagos from './PlanPagos';
  import CuotasCredito from './CuotasCredito';

  export default {
    name: 'ShowCredit',
    components: { InfoSolicitud, PlanPagos, CuotasCredito, VBtn },
    data () {
      return {
        reloadKey: 1,
        panel: null,
        info: {}
      }
    },
    mounted(){
      this.$http.get( 'api/admin/solicitudes/get/'+this.$route.params.id  )
        .then(res => {
            if(res.data.success){
              console.log(res.data)
              this.info = res.data.item;
              this.reloadKey++;
            }
        }).catch(err => {
          console.log(err);
        });
    },
    methods: {

    }

  }
</script>
